<template>

  <div>

    <div class="border-0 py-5 d-flex justify-content-end">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm"
        @click.prevent="createPeriodClicked"
      >
        <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{ $t('PERIOD.NEW') }}
      </a>
    </div>

    <div class="pt-0 pb-3 table-responsive">
      <b-table id="template-table" striped :fields="headers" :items="periods" head-variant="light">
        <template #cell(from)="row">
          <span class="text-dark-75 d-block font-size-lg">
            {{
              type === 'settings'
                ? getMonthName(row.item.from_month) + ' ' + row.item.from_day
                : row.item.from
            }}
          </span>
        </template>
        <template #cell(to)="row">
          <span class="text-dark-75 d-block font-size-lg">
            {{
              type === 'settings'
                ? getMonthName(row.item.to_month) + ' ' + row.item.to_day
                : row.item.to
            }}
          </span>
        </template>
        <template #cell(id)="row">
          <div class="justify-content-end d-flex">
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="selectPeriodClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a
              v-if="enableDelete(row.item)"
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="deletePeriodClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
            <a v-if="!enableDelete(row.item)" href="#" class="btn btn-icon btn-light btn-sm">
              <span class="svg-icon svg-icon-md svg-icon-primary">
              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'PeriodsTable',
  props: ['type', 'months'],
  emits: ['selectPeriodClicked', 'deletePeriodClicked', 'createPeriodClicked'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId','periods'])
  },
  watch: {
    periods(newValue, oldvalue) {}
  },
  mounted() {},
  methods: {
    setSelectedPeriodIds(period_ids) {

    },
    selectPeriodClicked(period_id) {
      this.$emit('selectPeriodClicked', period_id);
    },
    deletePeriodClicked(period_id) {
      this.$emit('deletePeriodClicked', period_id);
    },
    createPeriodClicked() {
      this.$emit('createPeriodClicked');
    },
    getMonthName(month_str) {
      for (var i = 0; i < this.months.length; ++i) {
        if (this.months[i].value === month_str) {
          return this.months[i].text;
        }
      }
      return '';
    },
    enableDelete(period) {
      var from = dayjs(period.from);
      var now = dayjs();

      return (this.type === 'live' && now < from) || this.type !== 'live';
      //return true;
    }
  },
  data() {
    return {
      headers: [
        {
          key: 'name',
          label: 'Namn',
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'active',
          label: 'Aktiv',
          thClass: 'pl-7',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return item.active ? 'Ja' : 'Nej'
          }
        },
        {
          key: 'from',
          label: this.$t('PERIOD.FROM'),
          thClass: 'w-165px',
          tdClass: 'w-165px',
          sortable: true
        },
        {
          key: 'to',
          label: this.$t('PERIOD.TO'),
          thClass: 'w-165px',
          tdClass: 'w-165px',
          sortable: true
        },
        {
          key: 'next_period_id',
          label: this.$t('PERIOD.NEXT_PERIOD'),
          thClass: 'w-165px',
          tdClass: 'w-165px',
          sortable: true,
          formatter: (_, __, item) => {
            return item.next_period ? item.next_period.name : this.$t('COMMON.NOT_SET')
          }
        },
        {
          key: 'id',
          label: '',
          thClass: 'w-110px text-right'
        }
      ],
      list: []
    };
  }
};
</script>

<template>
  <div id="page-live-periods">
    <Confirm
      :title="$t('PERIOD.CONFIRM')"
      :message="$t('PERIOD.CONFIRM_DELETE')"
      ref="deletePeriodConfirm"
      type="success"
      @confirm="onDeletePeriodConfirm"
    />

    <PeriodEditorModal 
      :period_id="selected_period_id"
      ref="period-editor-modal"

      @created="created"
      @updated="updated"
      @cancel="close_modal"
    />

    <PeriodTable
      :periods="periods"
      :months="months"
      type="live"
      @createPeriodClicked="createPeriod"
      @selectPeriodClicked="selectPeriod"
      @deletePeriodClicked="deletePeriodClicked"
    />
    <div class="scroll-to-container"></div>
    
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PeriodTable from '@/view/pages/ml/periods/PeriodTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import dayjs from 'dayjs';
import PeriodEditorModal from './PeriodEditorModal.vue';

import store from '@/core/services/store';

import {
  SET_PERIODS,
} from '@/core/services/store/common.module';


export default {
  name: 'PeriodsPage',
  components: {
    PeriodTable,
    PeriodEditorModal,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  props: ['months'],
  mounted() {

  },
  data() {
    return {
      selected_period_id: null,

      toDelete: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    }
  },
  methods: {

    close_modal() {
      this.$refs['period-editor-modal'].hide();
    },

    created(item) {
      const periods = this.periods;
      periods.push(item);

      store.dispatch(SET_PERIODS, periods);
    },

    updated(item) {
      const periods = this.periods;

      const index = periods.findIndex(i => item.id === i.id);

      if (index >= 0) {
        periods[index] = item;
      }

      store.dispatch(SET_PERIODS, periods);
    },

    createPeriod() {

      this.selected_period_id = null;

      this.$nextTick(()=>{
        this.$refs['period-editor-modal'].show();
      });
      
    },
    deletePeriodClicked(period) {
      this.toDelete = period;
      this.$refs['deletePeriodConfirm'].show();
    },
    onDeletePeriodConfirm() {
      this.deletePeriod(this.toDelete);
      this.toDelete = null;
    },
    deletePeriod(period_id) {
      axios
        .delete(`/period/${period_id}?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 204) {
            // this.periods = this.periods.filter(item => item.id !== period_id);

            this.creating = false;
            this.editing = false;

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PERIOD.DELETED'));

          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_DELETE'));
        });
    },

    selectPeriod(period_id) {
      this.selected_period_id = period_id;

      this.$nextTick(()=>{
        this.$refs['period-editor-modal'].show();
      });

    },

    
  }
};
</script>

<template>
  <div id="page-member-profile-news-editor-modal">
    <memlist-modal
      size="lg"
      ref="edit-form"
      :visible="show_modal"
      @close="onClose"
      hide-footer
    >

      <b-form class="mt-8">

        <memlist-checkbox 
          :text="$t('PERIOD.SHOW')"
          v-model="local_item.show"
        />

        <memlist-text-input
          class="mt-8"
          id="name"
          name="name"
          :title="$t('PERIOD.NAME')"
          layout="vertical"
          v-model="local_item.name"
          type="text"
        />


        <b-row>
          <b-col md="6" sm="12">
            <b-form-group id="input-group-2" :label="$t('PERIOD.FROM')" label-for="input-2">
              <memlist-date-picker v-model="local_item.from"></memlist-date-picker>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="input-group-3" :label="$t('PERIOD.TO')" label-for="input-3">
              <memlist-date-picker v-model="local_item.to"></memlist-date-picker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" sm="12">
            <b-form-group id="input-group-2" :label="$t('PERIOD.MDT_FROM')" label-for="input-2">
              <memlist-date-picker v-model="local_item.mdt_from"></memlist-date-picker>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="input-group-3" :label="$t('PERIOD.MDT_TO')" label-for="input-3">
              <memlist-date-picker v-model="local_item.mdt_to"></memlist-date-picker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="!local_item.is_infinite" md="6" sm="12">

            <memlist-select-input2
              id="script"
              name="script"
              :title="$t('PERIOD.YEAR')"
              layout="vertical"
              validation_type="TEXT"
              v-model="local_item.year"
              :items="year_options"
              :required="false"
              :invalid_text="''"
              :info_text="''"
            />

          </b-col>
          <b-col md="6" sm="12">

            <memlist-select-input2
              id="script"
              name="script"
              :title="$t('PERIOD.NEXT_PERIOD')"
              layout="vertical"
              validation_type="TEXT"
              v-model="local_item.next_period_id"
              :items="period_options"
              :required="false"
              :invalid_text="''"
              :info_text="''"
            />

          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton" />
          </b-col>
        </b-row>

    </b-form>
      
    </memlist-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import dayjs from 'dayjs';

export default {
  name: 'PeriodEditorModal',
  components: {
    RightModalSaveAndCloseButtons
  },
  props: ['period_id'],
  mixins: [toasts],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'customer', 'periods']),
    period_options() {
      return this.periods.map(item => ({ text: item.name, value: item.id }));
    } 
  },
  data() {
    return {
      local_item: {},
      show_modal: false,
      year_options: [],
    };
  },
  watch: {

    'local_item.from'() {
      if (this.local_item.id) {
        return;
      }

      this.local_item.mdt_from = this.local_item.from;

      this.local_item.year = dayjs(this.local_item.from).format('YYYY');
    },

    'local_item.to'() {
      if (this.local_item.id) {
        return;
      }

      this.local_item.mdt_to = this.local_item.to;
    },

    period_id: {
      handler(newVal) {
        if (newVal) {
          this.fetch_period(newVal);
        } else {
          this.local_item = { show: true };
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Additional initialization if necessary.

    this.year_options = [];

    const current_year = parseInt(dayjs().format('YYYY'));

    for (let year = 1900; year < current_year + 25; ++year) {
      this.year_options.push({ value: year, text: year+'' });
    }

    

  },
  methods: {

    onClose() {
      this.show_modal = false;
    },
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },
    async fetch_period(id) {
      try {
        const res = await axios.get(`/period/${id}`);
        if (res.status === 200) {
          this.local_item = res.data;
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_GET'));
        }
      } catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_GET'));
      }
    },
    
    async on_submit() {
      
      try {

        this.local_item.company_id = this.currentCompanyId;

        if (this.local_item.id) {
          const res = await axios.put(`/period/${this.local_item.id}`, this.local_item);

          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PERIOD.UPDATED'));

            this.$emit('updated', this.local_item);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_UPDATE'));
          }

        }
        else {
          const res = await axios.post(`/period`, this.local_item);

          if (res.status === 201) {
            this.local_item = res.data;

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PERIOD.CREATED'));

            this.$emit('created', res.data);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERIOD.UNABLE_CREATE'));
          }
        }

        
      }
      catch (err) {

      }

      this.$refs['saveButton'].stop();
    },

  }
};
</script>

<style scoped>

</style>
